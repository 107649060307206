<template>
  <div class="w-100">
    <div class="content">
      <el-card class="box-card min-h-600 zhgl">
        <div class="mod-title">账号信息</div>
        <div class="zhgl_cont">
          <el-row class="mar-t-20">
            <div>
              <div class="infor f-l mar-r-20">
                <div class="flex">
                  <img
                    src="@/assets/images/common/touxiang_shwj.svg"
                    width="70"
                    class="mar-r-20 touxiang"
                    v-if="version === 'shwj'"
                  />
                  <img
                    src="@/assets/images/common/touxiang2.svg"
                    width="70"
                    class="mar-r-20 touxiang"
                    v-else
                  />
                  <div class="lh-24">
                    <p>
                      <strong>管理者账号：</strong>
                      {{ bundle.root_user.name }}
                    </p>
                    <p>
                      <strong>企业ID：</strong>
                      {{ systemUser.domain }}
                    </p>
                    <p>
                      <strong>使用期限：</strong>
                      {{ bundle.expired }}
                    </p>
                    <p>
                      <strong>公司名称：</strong>
                      {{ systemUser.customer_name }}
                    </p>
                  </div>
                </div>
                <ul class="flex mar-t-10">
                  <li v-if="bundle.hasOwnProperty('plans')">
                    <span>方案数{{ bundle.plans.used }}</span>
                    <var>剩余{{ bundle.plans.remain }}</var>
                  </li>
                  <li v-if="bundle.hasOwnProperty('accounts')">
                    <span>账号数{{ bundle.accounts.used }}</span>
                    <var>剩余{{ bundle.accounts.remain }}</var>
                  </li>
                  <li v-if="bundle.hasOwnProperty('analyses')">
                    <span>分析数{{ bundle.analyses.used }}</span>
                    <var>剩余{{ bundle.analyses.remain }}</var>
                  </li>
                  <li v-if="bundle.hasOwnProperty('contacts')">
                    <span>通讯录人数{{ bundle.contacts.used }}</span>
                    <var>剩余{{ bundle.contacts.remain }}</var>
                  </li>
                </ul>
              </div>
              <img
                src="@/assets/images/common/shezhi_k.png"
                alt
                width="410"
                class="f-l mar-r-20"
                v-show="version !== 'shwj'"
              />
            </div>
          </el-row>
          <div v-if="systemUser.isadmin" class="mar-t-20">
            <el-button type="primary" round @click="addNewButton"
              >新增账号</el-button
            >
          </div>
          <div v-loading="loading">
            <ul class="mod-list lh-60 mar-t-10">
              <li>
                <h3>
                  <span class="num-tit">序号</span>
                  <span class="mod-list-title1">账号</span>
                  <span class="tag">身份</span>
                  <span class="tag">登录状态</span>
                  <span class="tag">强制下线</span>
                  <span class="tag">删除</span>
                  <span class="tag">密码重置</span>
                </h3>
              </li>
              <li v-for="(row, index) in users" :key="index">
                <span class="num bg-555" v-if="index == 0">1</span>
                <span class="num bg-689" v-else-if="index == 1">2</span>
                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                <span class="mod-list-title1">{{ row.username }}</span>
                <span class="tag">{{
                  row.isadmin == 1 ? '管理员' : '普通用户'
                }}</span>
                <span class="tag">{{ row.alive == 1 ? '在线' : '离线' }}</span>
                <span class="tag">
                  <i
                    class="iconfont icon-qiangzhixiaxian"
                    v-if="
                      systemUser.isadmin == 1 &&
                        row.alive == 1 &&
                        row.id != systemUser.id
                    "
                    @click="forceOut(row)"
                  ></i>
                  <i class="iconfont icon-qiangzhixiaxian jy" v-else></i>
                </span>
                <span class="tag">
                  <i
                    class="iconfont icon-shanchu"
                    @click="deleteUser(row, index)"
                    v-if="systemUser.isadmin && !row.isadmin"
                  ></i>
                  <i class="iconfont icon-shanchu jy" v-else></i>
                </span>
                <span class="tag">
                  <i
                    class="iconfont icon-mimazhongzhi"
                    @click="resetAction()"
                    v-if="systemUser.id === row.id"
                  ></i>
                  <i
                    class="iconfont icon-mimazhongzhi"
                    @click="resetPasswordDialog = { visible: true, user: row }"
                    v-else-if="systemUser.isadmin && !row.isadmin"
                  ></i>
                  <i class="iconfont icon-mimazhongzhi jy" v-else></i>
                </span>
              </li>
            </ul>
            <div class="page-bottom">
              <el-pagination
                :page-size="10"
                :total="total"
                :hide-on-single-page="true"
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
              />
            </div>
          </div>
          <!-- 新增账号弹出框 -->
          <el-dialog
            :visible.sync="addNew"
            class="new-dialog"
            :close-on-click-modal="false"
            @close="dialogClose"
          >
            <div class="mod-title" slot="title">新增账号</div>
            <div class="cont">
              <el-form label-width="60px">
                <el-form-item label="账号">
                  <el-input
                    v-model="create_account.username"
                    placeholder="请输入账号"
                    class="w-200"
                  ></el-input>
                </el-form-item>
                <el-form-item label="昵称">
                  <el-input
                    v-model="create_account.nickname"
                    placeholder="请输入昵称"
                    class="w-200"
                  ></el-input>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input
                    v-model="create_account.password"
                    placeholder="请输入密码"
                    class="w-200"
                    type="password"
                  ></el-input>
                  <password
                    v-model="create_account.password"
                    :strength-meter-only="true"
                    @score="showScore"
                    @feedback="showFeedback"
                  />
                  <span style="line-height:20px;font-size:12px;">{{
                    password_reference
                  }}</span>
                </el-form-item>
              </el-form>
            </div>
            <div class="button-group mar-t-40">
              <el-button type="primary" round @click="save">确定</el-button>
              <el-button round @click="addNew = false">取消</el-button>
            </div>
          </el-dialog>
          <!--@end 新增账号弹出框 -->
          <!-- <div v-if="addNew">
            <el-row>
              <el-col class="xzyh_cont">
                <div class="mar-t-10">
                  <el-input
                    placeholder="请输入账号"
                    v-model.trim="user.name"
                    class="w-310"
                  ></el-input>
                </div>
                <span class="cor-blue mar-t-5">首次登陆需要重置密码</span>
                <div class="button-group">
                  <el-button type="primary" round @click="save">确定</el-button>
                  <el-button round @click="addNew = false">取消</el-button>
                </div>
              </el-col>
            </el-row>
          </div> -->
        </div>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="addDialog.visible"
      class="Dialog_style1"
      :close-on-click-modal="false"
    >
      <div class="mod-title" slot="title">新增账号成功</div>
      <div class="cont">
        <ul>
          <li>
            <img
              src="@/assets/images/common/icon/blue-dot.svg"
              alt
              width="14"
            />账号：
            <strong>{{ addDialog.username }}</strong>
          </li>
          <li>
            <img
              src="@/assets/images/common/icon/blue-dot.svg"
              alt
              width="14"
            />密码：
            <strong>{{ addDialog.password }}</strong>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="limitDialog"
      class="Dialog_style2 kyzh"
      :close-on-click-modal="false"
    >
      <el-row class="w-100 dp_box">
        <el-col :span="13">
          <div class="img">可用账号数达上限</div>
        </el-col>
        <el-col :span="11">
          <p>
            您购买的
            <strong>{{ bundle.bundle_name }}</strong>
          </p>
          <p class="fw-b">
            可用账号数已达上限：
            <span class="red">{{ bundle.accounts.used }}个</span>
          </p>
          <p class="mar-t-10">请删除账号后再新增，</p>
          <p>或联系客服人员</p>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 确定重置密码弹出框 -->
    <el-dialog
      :close-on-click-modal="false"
      class="resetPasswordDialog Dialog_style1"
      :visible.sync="resetPasswordDialog.visible"
    >
      <div class="mod-title" slot="title">确定重置密码</div>
      <div class="cont">
        <ul>
          <li>
            <img
              src="@/assets/images/common/icon/red-dot.svg"
              alt
              width="14"
            />该账户将无法再使用旧密码登录
          </li>
          <li>
            <img
              src="@/assets/images/common/icon/red-dot.svg"
              alt
              width="14"
            />系统将自动产生一组新密码
          </li>
          <li>
            <img
              src="@/assets/images/common/icon/red-dot.svg"
              alt
              width="14"
            />此账号再次登录需输入此组默认密码再重置密码
          </li>
        </ul>
      </div>
      <div class="button-group">
        <el-button
          round
          type="primary"
          @click="resetPassword(resetPasswordDialog.user)"
          >确定重置</el-button
        >
        <el-button round @click="resetPasswordDialog.visible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="resetDialog.visible"
      class="Dialog_style1"
      :close-on-click-modal="false"
    >
      <div class="mod-title" slot="title">重置密码成功</div>
      <div class="cont">
        <ul>
          <li>
            您的账号：
            <strong>{{ resetDialog.username }}</strong>
          </li>
          <li>
            密码已重置为：
            <strong>{{ resetDialog.password }}</strong>
          </li>
        </ul>
      </div>
    </el-dialog>
    <!--  当前用户重置密码  -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="resetPwd.visible"
      width="400px"
      class="dialogVisible6 header-reset"
    >
      <div class="czmm-content">
        <h3 class="mod-title">
          <span>密码重置</span>
        </h3>
        <div class="form_box">
          <div class="input_box_item">
            <div class="input_box">
              <i class="iconfont icon-yaoshi"></i>
              <el-form>
                <el-form-item>
                  <el-input
                    placeholder="请输入原始密码"
                    type="password"
                    id="oldPass"
                    @input="check('oldError')"
                    v-model.trim="resetPwd.old_pass"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <span class="ts">{{ resetPwd.oldError }}</span>
          </div>
          <div class="input_box_item">
            <div class="input_box">
              <i class="iconfont icon-mima"></i>
              <el-form>
                <el-form-item>
                  <el-input
                    placeholder="请输入新密码"
                    type="password"
                    id="newPass"
                    @input="check('newError')"
                    v-model.trim="resetPwd.new_pass"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <span class="ts">{{ resetPwd.newError }}</span>
          </div>
          <div class="input_box_item">
            <div class="input_box">
              <i class="iconfont icon-mima"></i>
              <el-form>
                <el-form-item>
                  <el-input
                    placeholder="请再次输入新密码"
                    type="password"
                    id="repeatPass"
                    @input="check('repeatError')"
                    v-model.trim="resetPwd.repeat_pass"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <span class="ts">{{ resetPwd.repeatError }}</span>
          </div>
          <el-button
            type="primary"
            round
            size="medium"
            id="beforeLogin"
            :disabled="true"
            >确定</el-button
          >
          <el-button
            type="primary"
            round
            size="medium"
            id="afterLogin"
            element-loading-background="rgba(98, 100, 102, 0.6)"
            class="primary hide"
            @click="resetPass"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <confirm ref="confirmDel" title="删除账号" confirm="删除" @must="mustDel" />
    <confirm ref="confirmOut" title="退出系统" confirm="退出" @must="mustOut" />
  </div>
</template>
<script type="application/javascript">
import {
  users as customerUsers,
  delUser,
  user_reset_pass,
  user_force_logout,
  user_create_v2
} from '@/api/customer';
import confirm from '@components/common/confirm.vue';
import { changePass } from '@/api/user';
import { localDbClear } from '@/utils/helpers';

import Password from 'vue-password-strength-meter';

export default {
  name: 'component-account',
  components: {
    confirm,
    Password
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      addDialog: { visible: false, username: '', password: '' },
      limitDialog: false,
      resetDialog: { visible: false, username: '', password: '' },
      resetPasswordDialog: { visible: false, user: null },
      resetPwd: {
        visible: false,
        oldError: '',
        newError: '',
        repeatError: '',
        old_pass: '',
        new_pass: '',
        repeat_pass: ''
      },
      users: [],
      user: { name: '' },
      addNew: false,
      loading: false,
      dialogVisible: true,
      password_reference: '',
      total: 0,
      currentPage: 1,
      create_account: {
        username: '',
        password: '',
        nickname: ''
      }
    };
  },
  mounted() {
    this.loadUsers();
    this.$nextTick(() => {
      this.headerEvent();
    });
  },
  computed: {
    systemUser() {
      return this.$store.state.user.user;
    },
    bundle() {
      return this.$store.state.user.bundle;
    }
  },
  methods: {
    showFeedback({ suggestions, warning }) {},
    showScore(score) {
      let scoreDot = '';
      switch (score) {
        case 0:
          scoreDot = '太容易被猜到了';
          break;
        case 1:
          scoreDot = '比较容易被猜到';
          break;
        case 2:
          scoreDot = '可能被猜到';
          break;
        case 3:
          scoreDot = '安全可靠';
          break;
        case 4:
          scoreDot = '非常安全可靠';
          break;
      }
      this.password_reference = scoreDot;
    },
    loadUsers() {
      this.loading = true;
      customerUsers({ page: this.currentPage })
        .then(res => {
          if (res.data.state) {
            const { data, total } = res.data.data;
            this.users = data.filter(user => {
              if (this.systemUser.isadmin) {
                return true;
              } else {
                return parseInt(this.systemUser.id) === parseInt(user.id);
              }
            });
            this.total = total;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error('加载用户列表失败，服务错误');
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadUsers();
    },
    addNewButton() {
      if (this.bundle.accounts.remain <= 0) {
        this.limitDialog = true;
        return false;
      } else {
        this.addNew = true;
      }
    },
    deleteUser(user, index) {
      let textBox = [
        { type: 'error', text: '您将不能再新增与此账号同名的账号' },
        { type: 'error', text: '此账号创建的收藏夹相关资料将被删除' },
        { type: 'success', text: '此账号创建的方案与报告将被保留' }
      ];
      this.$refs.confirmDel.show(textBox, { id: user.id, index });
    },
    mustDel(data) {
      const { id, index } = data;
      delUser(id)
        .then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.users.splice(index, 1);
            this.bundle.accounts.remain = this.bundle.accounts.remain + 1;
            this.$store.dispatch('storeBundle', this.bundle);
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        })
        .catch(() => {
          this.$message.error('删除用户失败，服务错误');
        });
    },
    resetPassword(user) {
      var _this = this;
      user_reset_pass({ id: user.id })
        .then(res => {
          if (res.data.state) {
            let result = res.data.data;
            _this.resetDialog.username = result.username;
            _this.resetDialog.password = result.password;
            _this.resetPasswordDialog.visible = false;
            _this.resetDialog.visible = true;
          } else {
            _this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        })
        .catch(res => {
          _this.$message.error('删除用户失败，服务错误');
        });
    },
    forceOut(row) {
      let textBox = [{ type: 'error', text: '强制该账号退出系统' }];
      this.$refs.confirmOut.show(textBox, { id: row.id });
    },
    mustOut(data) {
      user_force_logout(data)
        .then(res => {
          if (res.data.state) {
            if (data.id == this.systemUser.id) {
              window.location.href = '/login';
            } else {
              this.$message.success('成功将该账号强制退出');
              this.loadUsers();
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('强制退出失败，服务错误');
        });
    },
    headerEvent() {
      const _this = this;
      var reset = window.document.getElementsByClassName('header-reset')[0];
      window.jQuery(reset).off('keydown');
      window.jQuery(reset).keydown(function(event) {
        if (event.keyCode === 13) {
          _this.resetPass();
          return false;
        }
      });
    },
    resetAction() {
      this.resetPwd = {
        visible: true,
        oldError: '',
        newError: '',
        repeatError: '',
        old_pass: '',
        new_pass: '',
        repeat_pass: ''
      };
    },
    check(key) {
      this.resetPwd[key] = '';
      let oldPass = this.resetPwd.old_pass;
      let newPass = this.resetPwd.new_pass;
      let repeatPass = this.resetPwd.repeat_pass;

      if (
        oldPass.length >= 6 &&
        newPass.length >= 6 &&
        repeatPass.length >= 6
      ) {
        window.document.getElementById('beforeLogin').classList.add('hide');
        window.document.getElementById('afterLogin').classList.remove('hide');
      } else {
        window.document.getElementById('afterLogin').classList.add('hide');
        window.document.getElementById('beforeLogin').classList.remove('hide');
      }
    },
    resetPass() {
      let oldPass = this.resetPwd.old_pass;
      let newPass = this.resetPwd.new_pass;
      let repeatPass = this.resetPwd.repeat_pass;

      if (oldPass.length > 18 || oldPass.length < 6) {
        this.resetPwd.oldError = '密码长度6~18位字符串';
        this.$message.warning('密码长度6~18位字符串');
        return false;
      }

      if (repeatPass.length > 18 || repeatPass.length < 6) {
        this.resetPwd.repeatError = '新密码长度6~18位字符串';
        this.$message.warning('新密码长度6~18位字符串');
        return false;
      }

      if (repeatPass !== newPass) {
        this.resetPwd.repeatError = '两次输入密码不一致，请重新输入';
        this.$message.warning('两次输入密码不一致，请重新输入');
        return false;
      }

      changePass({
        old_pass: oldPass,
        new_pass: newPass,
        repeat_pass: repeatPass
      })
        .then(res => {
          if (res.data.state) {
            this.$message.success('重置密码成功，请重新登陆');
            localDbClear();
            window.location.href = '/login';
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          this.$message.error('重置密码失败，服务错误');
          window.console.error(err);
        });
    },
    save() {
      let user = this.user;
      if (
        this.create_account.username.length < 3 ||
        this.create_account.username.length > 18
      ) {
        this.$message.warning('请填写用户账号3~18位字符串');
        return false;
      }
      if (this.bundle.accounts.remain <= 0) {
        this.limitDialog = true;
        return false;
      }
      user_create_v2({
        nickname: this.create_account.nickname,
        username: this.create_account.username,
        password: this.create_account.password
      })
        .then(res => {
          if (res.data.state) {
            let result = res.data.data;
            this.addDialog.username = result.username;
            this.addDialog.password = result.password;
            this.addDialog.visible = true;
            this.create_account.nickname = '';
            this.create_account.username = '';
            this.create_account.password = '';
            this.addNew = false;
            this.loadUsers();
            this.bundle.accounts.remain = this.bundle.accounts.remain - 1;
            this.$store.dispatch('storeBundle', this.bundle);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('创建失败，服务错误');
        });
    },
    dialogClose() {
      this.create_account.nickname = '';
      this.create_account.username = '';
      this.create_account.password = '';
    }
  }
};
</script>
<style scoped>
.zhgl >>> .el-card__body {
  padding: 40px;
}
.zhgl .mod-title {
  margin-left: 0;
}
/*折角*/
.zhgl .infor {
  width: 350px;
  height: 160px;
  background-image: url(../../assets/images/common/card_xinxi1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  padding: 40px;
  font-size: 14px;
  color: #414141;
}
.zhgl .infor .touxiang {
  height: 70px;
  margin-top: 12px;
}
.lh-24 {
  line-height: 24px;
}
.infor li:not(:last-child) {
  width: 23%;
  border-right: 1px solid #f2f2f2;
}
.infor li {
  text-align: center;
  font-size: 12px;
  line-height: 30px;
}
.infor li:last-child {
  width: 31%;
}
.infor li span,
.infor li var {
  display: block;
}
.infor li var {
  font-size: 14px;
}
.zhgl .mod-list-title1 {
  width: 20%;
}
.zhgl .num-tit {
  width: 100px;
}
.zhgl li {
  font-size: 14px;
}
.zhgl .num {
  margin-right: 82px;
}
.xzyh_cont {
  font-size: 12px;
  line-height: 30px;
}
/*重置密码弹出框*/
.dialogVisible6 >>> .el-dialog {
  background: none;
  box-shadow: none;
}
.dialogVisible6 >>> .el-dialog__headerbtn {
  display: none;
}
.czmm-content {
  position: relative;
  background: #fff;
  border-radius: 0.5em;
  width: 100%;
  height: 450px;
}
.dialogVisible6 .mod-title {
  border-bottom: 1px solid rgba(8, 35, 83, 0.09);
  font-size: 14px;
  padding: 23px 0;
  border-left: none;
  margin-left: 0;
}
.dialogVisible6 .mod-title span {
  margin-left: 25px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  height: 14px;
}
.dialogVisible6 .form_box {
  margin: 40px;
}
.dialogVisible6 .input_box_item {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.dialogVisible6 .input_box {
  border: solid 1px #d2d2d2;
  background: #fff;
  border-radius: 6px;
  display: flex;
  padding: 0 0.5em;
  font-size: 14px;
  position: relative;
  margin-bottom: 35px;
  line-height: 45px;
}
.dialogVisible6 .input_box img {
  margin: 0 5px;
}
.dialogVisible6 .input_box .el-form {
  width: 100%;
  line-height: 45px;
}
.dialogVisible6 .el-form-item--small.el-form-item {
  margin-bottom: 0;
  line-height: 45px;
  margin-top: 0;
}
.dialogVisible6 .input_box_item span {
  position: absolute;
  right: 0;
  z-index: 5;
  color: red;
  bottom: 0;
  font-size: 12px;
}
.dialogVisible6 .el-button {
  width: 100%;
  margin: 10px 0;
  line-height: 40px;
  height: 40px;
  padding: 0;
  font-size: 14px;
}
.dialogVisible6 .el-button.primary {
  background: linear-gradient(
    90deg,
    rgba(93, 101, 250, 1),
    rgba(102, 97, 254, 1)
  );
}
.dialogVisible6 .input_box >>> .el-input__inner {
  width: 100%;
  border: none;
  padding-left: 5px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  background: #fff;
}
</style>
